export const campaign_payment = {
  get: {
    url: "/payment/getPendingBankPayments",
    method: "GET",
    id: null,
  },
  approve: {
    url: "/payment/approveBankPayment/",
    method: "GET",
    id: null,
  },
  reject: {
    url: "/payment/rejectBankPayment/",
    method: "GET",
    id: null,
  },
};
