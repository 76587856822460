<template>
  <b-modal id="bonus-modal" hide-footer>
    <FormulateForm @submit="addBonus" :values="formValues">
      <FormulateInput
        label="Please input the bonus here"
        name="bonus_amount"
        type="number"
      />
      <FormulateInput
        type="submit"
        :disabled="isLoading"
        :label="isLoading ? 'Changing...' : 'Change'"
      />
    </FormulateForm>
  </b-modal>
</template>

<script>
import { campaigns } from "@/config/api/campaigns";
export default {
  props: ["data","value"],
  data() {
    return {
      isLoading: false,
      formValues: {
        bonus_amount: this.value,
      },
    };
  },
  watch: {
    value(value) {
      this.formValues.bonus_amount = value;
    },
  },
  methods: {
    addBonus(value) {
      const api = campaigns.addBonus;
      api.data = {
        campaignId: this.data._id,
        bonus_amount: value.bonus_amount,
      };
      this.isLoading = true;
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Bonus updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
    
          this.$emit("bonusChanged", value.bonus_amount);
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Bonus is not updated! ${err.response.data.error}`,
            {
              title: "Update",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
