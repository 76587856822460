<template>
  <div class="add-driver">
    <b-button @click="openModal" variant="primary"
      >{{
        data && data.wrapping_station ? "Update" : "Assign"
      }}
      Workstation</b-button
    >

    <b-modal
      :id="`assign-workstation-modal${uuid}`"
      title="Workstation"
      hide-footer
    >
      <label class="typo__label">Please choose a workstation</label>
      <multiselect
        v-model="value"
        :options="availableWorkstations"
        :custom-label="nameWithLang"
        placeholder="Select one"
        label="name"
        track-by="_id"
      ></multiselect>
      <FormulateInput
        type="datetime-local"
        v-model="schedule_date"
        label="Schedule time"
        placeholder="Schedule time"
      />
      <b-button class="my-5 float-right" @click="assignWorkstation">
        <span v-if="assigning"> Submitting...</span>
        <span v-else> Submit </span>
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { workstations } from "@/config/api/workstations";
import { driver } from "@/config/api/drivers";
import uuid from "@/utils/idGen";
export default {
  props: ["campaignId", "data"],
  components: {
    Multiselect,
  },

  data() {
    return {
      availableWorkstations: [],
      value: null,
      schedule_date: null,
      assigning: false,
    };
  },

  computed: {
    uuid() {
      return uuid();
    },
  },

  methods: {
    getAvaialbleWorkstations() {
      const api = workstations.get;
      this.generateAPI(api).then((res) => {
        this.availableWorkstations = res.data.workstation;
      });
    },
    assignWorkstation() {
      if (!this.value) {
        this.$bvToast.toast("Please choose one workstation!", {
          title: "Assign workstation",
          variant: "danger",
          solid: true,
        });
        return;
      }
      this.assigning = true;
      const api = driver.assignWorkstation;
      api.data = {
        campaignId: this.campaignId,
        workstationId: this.value._id,
        schedule_date: (this.schedule_date && this.schedule_date) || null,
      };

      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Workstation is added successfully", {
            title: "Assign workstation",
            variant: "success",
            solid: true,
          });
          this.$bvModal.hide(`assign-workstation-modal${this.uuid}`);
          this.$emit("reload");
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Workstation is not added, ${err.response.data.error}!`,
            {
              title: "Assign workstation",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.assigning = false;
        });
    },

    nameWithLang({ name, city }) {
      return `${name} — (${city})`;
    },
    async openModal() {
      await this.getAvaialbleWorkstations();
      if (this.data) {
        if (this.data.wrapping_station) {
          this.value = this.data.wrapping_station;
        }
        if (this.data.schedule_date) {
          this.schedule_date = this.moment(this.data.schedule_date).format(
            "yyyy-MM-DDThh:mm"
          );
        }
      }

      this.$bvModal.show(`assign-workstation-modal${this.uuid}`);
    },
  },
};
</script>

<style></style>
