<template>
    <div>
      <b-button @click="openModal" variant="primary" size="sm" class="p-0 ml-2"
        ><i class="ri-pencil-line"></i> Change Status </b-button
      >
      <b-modal id="status-change-modal" title="Change status" hide-footer>
       
        <FormulateForm
          class="flat-rate-form"
          @submit="changeFlatValue"
          v-model="formValues"
        >
          <FormulateInput
            name="status"
            type="select"
            label="Status"
            :options="statuses"
            v-model="selectedCampaign"
            validation="required"
          />
          <div class="text-right">
            <FormulateInput
              type="submit"
              :label="changing ? 'Changing...' : 'Change'"
            />
          </div>
        </FormulateForm>
      </b-modal>
    </div>
  </template>
  
  <script>
  import { campaigns } from "@/config/api/campaigns";
  export default {
    props: ["data", "options", "selectedCampaign"],
    data() {
      return {
        formValues: {},
        changing: false,
     
      };
    },

    computed: {
        statuses() {
      return this.options
        ? this.options.map((item) => {
            return {
              label: item.status,
              value: item.id,
            };
          })
        : [];
    },
    },
    methods: {
      changeFlatValue() {
        console.log(this.options);
        console.log(this.selectedCampaign);
        const api = campaigns.updatecampaign;
        
        api.id = this.data._id;
  
        api.data = {
          status: this.formValues.status,
        };
  
        this.changing = true;
  
        this.generateAPI(api)
          .then((res) => {
            this.$emit("change", res.data.status);
            this.$bvToast.toast("Status is changed!", {
              title: "Status",
              variant: "success",
              solid: true,
            });
            this.$emit("closeModal");
            this.$bvModal.hide("status-change-modal");
            this.$emit("reloadData");
           
          })
          .catch((err) => {
            this.$bvToast.toast(
              `Status is not changed!, ${err.response.data.error}`,
              {
                title: "Status",
                variant: "danger",
                solid: true,
              }
            );
          })
          .finally(() => {
            this.changing = false;
          });
      },
      openModal() {
        this.formValues.status = this.data.status;
        this.$bvModal.show("status-change-modal");
      },
    },
  };
  </script>
  
  <style></style>
  