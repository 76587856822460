<template>
  <b-card>
    <div v-if="loading">Loaidng...</div>
    <div v-else>
      <div v-if="payment_details">
        <div v-if="performAction" style="text-align: center">Updating...</div>
        <h6>Approve or Reject payment</h6>
        <div><strong>Ref No.</strong> {{ payment_details.ref_no }}</div>
        <div>
          <strong>Created</strong>
          {{ moment(payment_details.createdAt).format("lll") }}
        </div>
        <div style="text-align: center">
          <a :href="payment_details.evidence" target="_blank">
            <img
              style="width: 180px"
              :src="payment_details.evidence"
              alt="Evidence"
              srcset=""
            />
          </a>
        </div>
        <div
          style="
            background-color: #fafafa;
            padding: 4px 8px;
            border-radius: 8px;
            margin-top: 14px;
          "
        >
          Company
          <strong>{{
            payment_details.campaign && payment_details.campaign.company.name
          }}</strong>
          <br />
          Plan price
          <strong>{{
            payment_details.campaign && payment_details.campaign.plan_price
          }}</strong>
          SAR
        </div>

        <div class="d-flex justify-content-between mt-5">
          <b-button
            variant="danger"
            @click="paymentAction(payment_details._id, 0)"
            :disabled="performAction"
          >
            Reject
          </b-button>
          <b-button
            variant="primary"
            @click="paymentAction(payment_details._id, 1)"
            :disabled="performAction"
          >
            Approve
          </b-button>
        </div>
      </div>
      <div v-else style="text-align: center">
        <make-payment :id="id" v-on:reload="$emit('reload')"/>
      </div>
    </div>
  </b-card>
</template>

<script>
import { campaign_payment } from "@/config/api/campaign_payment";
import { campaigns } from "@/config/api/campaigns";
import MakePayment from "./make-payment.vue";
export default {
  props: ["id"],
  data() {
    return {
      payment_details: {},
      loading: false,
      performAction: false,
    };
  },
  created() {
    this.loadCampaign(this.id);
  },
  components: {
    MakePayment,
  },

  methods: {
    loadCampaign(val) {
      const api = campaigns.campaignPayments;
      api.id = val;
      this.loading = true;
      this.generateAPI(api)
        .then((res) => {
          console.log("Hey->", res.data);
          this.payment_details = res.data.payments;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    paymentAction(id, type) {
      type ? this.approvePayment(id) : this.rejectPayment(id);
    },
    approvePayment(id) {
      const api = campaign_payment.approve;
      api.id = id;
      this.$bvModal
        .msgBoxConfirm("Are you sure do you want to approve the campaign?", {
          title: "Payment approve",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.performAction = true;
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Payment accepted successfully", {
                  title: "Payment accept",
                  variant: "success",
                  solid: true,
                });
                this.$emit("loadCampaign");
              })
              .catch((err) => {
                this.$bvToast.toast(
                  `Payment approve not carried out, ${err.response.data}`,
                  {
                    title: "Payment approve",
                    variant: "danger",
                    solid: true,
                  }
                );
              })
              .finally(() => {
                this.performAction = false;
              });
          }
        })
        .catch(() => {
          // An error occurred
        });
    },
    rejectPayment(id) {
      const api = campaign_payment.reject;
      api.id = id;
      this.$bvModal
        .msgBoxConfirm("Are you sure do you want to reject the campaign?", {
          title: "Payment reject",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.performAction = true;
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Payment rejected successfully", {
                  title: "Payment reject",
                  variant: "success",
                  solid: true,
                });
                this.$emit("loadCampaign");
              })
              .catch((err) => {
                this.$bvToast.toast(
                  `Payment reject not carried out, ${err.response.data}`,
                  {
                    title: "Payment reject",
                    variant: "danger",
                    solid: true,
                  }
                );
              })
              .finally(() => {
                this.performAction = false;
              });
          }
        })
        .catch(() => {
          // An error occurred
        });
    },
  },
};
</script>
