<template>
  <div>
    <b-button @click="openModal" variant="primary" size="sm" class="p-0 ml-2">
      <i class="ri-pencil-line"></i> Change
    </b-button>
    <b-modal id="update-cities-modal" title="Update Cities" hide-footer>
      <label>Update City</label>
      <v-select multiple v-model="formValues" :options="this.region" />
      <div class="text-right mt-3">
        <b-button
          @click="changeFlatValue"
          :disabled="changing"
          variant="primary"
        >
          {{ changing ? "Changing..." : "Change" }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { campaigns } from "@/config/api/campaigns";
import "vue-select/dist/vue-select.css";

export default {
  props: ["data"],
  data() {
    return {
      formValues: [],
      changing: false,
      region: [],
    };
  },

  components: {
  },

  created() {
    this.RegionCityList();
  },
  methods: {
    changeFlatValue() {
      const api = campaigns.updatecampaign;
      
      api.id = this.data._id;

      api.data = {
        cities: this.formValues,
      };

      this.changing = true;
      this.generateAPI(api)
        .then((res) => {
          this.$emit("change", res.data.cities);
          this.$bvToast.toast("Updated City!", {
            title: "No. Update City",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          this.$bvToast.toast(
            `No. Update City are not changed!, ${err.response.data.error}`,
            {
              title: "No. Update City",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.changing = false;
        });
    },

    //regionlist
    RegionCityList() {
      const api = campaigns.regionlist;
      this.generateAPI(api)
        .then((res) => {
          this.region = res.data.regions.map((itm) => itm.name);
          console.log("res" , res.data.regions );
        })
        .catch((err) => {
          console.log(err);
        });
    },

    openModal() {
      this.formValues = this.data.cities;
      this.$bvModal.show("update-cities-modal");
    },
  },
};
</script>

<style></style>
